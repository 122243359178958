export const environment = {
  apiEndpoint: 'https://pc-my-gateway-api.nt-development.dev/api',
  recaptchaSiteKey: '6Lf66PcpAAAAAIJcq5_yB_Z3hBJcsST19K7DG5fQ',
  tablePageSizeOption: [5, 10, 20, 50, 100],
  featuresFlag: {
    home: true,
    vouchers: true,
    balanceHistory: true,
    identityVerification: true,
    logout: true,
    deposit: true,
    login: true,
    withdraw: true,
    cryptoTransactions: true,
    buyVoucher: true,
    security: true,
    redeemVoucher: true,
  }
};
